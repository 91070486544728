'use client';

import {
    BlogCard,
    Column,
    EmptyState,
    Loader,
    NewsCard,
    Row,
    Slider,
    variables,
} from 'oddsgate-ds';
import {
    IArchiveGrid,
    IFilters,
    IPost,
    Taxonomy,
} from '@/lib/wordpress/_types/archiveGrid';
import React, { Suspense, useMemo, useState } from 'react';

import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import Link from 'next/link';
import { formatDate } from '@/lib/helpers/dateFormate';
import { useGlobalContext } from '@/lib/globalContext';
import styled from 'styled-components';

const OverlayCards = styled.div`
    width: 100%;
    min-height: 400px;
    position: relative;

    display: grid;
    place-items: center;
    figure {
        position: absolute;
        cursor: pointer;
        max-width: 400px;
    }

    figure:first-child {
        z-index: 21;
        /* transform: rotate(25deg); */
        transform: rotate(14deg) translate(20%, 10%);
    }

    figure:last-child {
        z-index: 20;
        transform: rotate(-10deg);
    }

    @media only screen and (max-width: ${variables.responsiveMedia}) {
        figure {
            position: inherit;
            transform: rotate(0deg) translate(0, 0);
            &:first-child {
                transform: rotate(0deg) translate(0, 0);
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

            &:last-child {
                transform: rotate(0deg);
            }
        }
    }
`;

export default function BlockBlogOverlay({ attrs }: IArchiveGrid) {
    const { grid, className } = attrs || {};

    const [loading, setLoading] = useState<boolean | null>(false);

    const [filteredPosts, setFilteredPosts] = useState<IPost[] | undefined>(
        grid?.items
    );

    const state = useGlobalContext();

    const { lang } = state;

    const cachedContent = useMemo(() => {
        if (filteredPosts && filteredPosts.length !== 0) {
            return filteredPosts.map((post, i: number) => {
                if (!post) return false;
                const category =
                    (post?.categories as unknown as Taxonomy[]) || [];
                const categoryName =
                    category && Object.keys(category).length > 0
                        ? category[0]?.items[0]?.name
                        : '';

                const imageElement = (
                    <DisplayImage
                        url={post?.image as string}
                        alt={post?.title ? (post?.title as string) : ''}
                        fill={true}
                    />
                );

                return (
                    <BlogCard
                        variant="default"
                        date={formatDate(post?.date as string, 'DATE_SHORT')}
                        category={categoryName}
                        imageElement={imageElement}
                        title={post.title as string}
                        description={post.excerpt as string}
                        linkElement={
                            <Link
                                href={post.slug as string}
                                aria-label={`Read more about ${post.title}`}
                                role="link"
                                className="linker"></Link>
                        }
                    />
                );
            });
        }
    }, [filteredPosts]);

    return (
        <Row className={className}>
            {loading ? (
                <Column className="text-center pt-5 pb-5">
                    <Loader label={state?.dict?.common?.loading}></Loader>
                </Column>
            ) : (
                <Suspense
                    fallback={
                        <Loader label={state?.dict?.common?.loading}></Loader>
                    }>
                    {cachedContent ? (
                        <OverlayCards>
                            {cachedContent.map((block) => {
                                return block;
                            })}
                        </OverlayCards>
                    ) : (
                        <Column className="pt-7 pb-5">
                            <EmptyState
                                variant={'light'}
                                label={
                                    state?.dict?.common?.noResults
                                }></EmptyState>
                        </Column>
                    )}
                </Suspense>
            )}
        </Row>
    );
}
